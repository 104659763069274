import React from 'react';
import {OrderBlock, Row, Text, Button} from '../../Widgets';
import {dateUtil} from '../../Utils';
import {MONTHLY_STATUS} from '../../dictionary';
import {Context} from '../../AppContext';
import PaymentOffline, {metadata} from '../../Modals/PaymentOffline-Monthly';
import {errorHandler} from '../../errors';
import {Divider} from '../../Widgets';
const config = require('../../data.json');

export default function PaymentSection({record, onUpdate}) {
  const app = React.useContext(Context);

  const editRecordRemit = React.useCallback(
    async (values) => {
      await app.actions.editMonthlyRemit({
        id: record?.id,
        ...values,
      });
      onUpdate();
    },
    [record, app.actions, onUpdate],
  );

  if (!record) {
    return null;
  }

  const textColor = record.payment_status !== 'success' && config.colors.main;

  return (
    <OrderBlock title="繳費資訊" color={config.colors.main}>
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          繳費狀態：
        </Text>
        <Text size="sm" color={textColor}>
          {' '}
          {MONTHLY_STATUS[record.payment_status]}
        </Text>
      </Row>
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          繳費期限：
        </Text>
        <Text size="sm" color={textColor}>
          {dateUtil.format(record.pay_deadline, 'YYYY-MM-DD HH:mm')}
        </Text>
      </Row>
      <Row>
        <Button
          onClick={() =>
            app.actions.setModal({
              content: (
                <PaymentOffline
                  values={{
                    remittance_account: record.remittance_account,
                    remittance_number: record.remittance_number,
                    remitted_time: record.remitted_time,
                  }}
                  onClose={() => app.actions.setModal()}
                  onEdit={editRecordRemit}
                />
              ),
              ...metadata,
            })
          }
          style={{marginRight: 10}}>
          填入匯款資訊
        </Button>
        {!config.information.offline_bank && (
          <Text size="sm" color={textColor}>
            請聯絡客服取得匯款資訊
          </Text>
        )}
      </Row>
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          匯款戶名：
        </Text>
        <Text size="sm" color={textColor}>
          {record.remittance_account}
        </Text>
      </Row>
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          匯款帳號後五碼：
        </Text>
        <Text size="sm" color={textColor}>
          {record.remittance_number}
        </Text>
      </Row>
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          匯款時間：
        </Text>
        <Text size="sm" color={textColor}>
          {dateUtil.format(record.remitted_time, 'YYYY-MM-DD HH:mm')}
        </Text>
      </Row>
      <Row>
        <Text size="sm" color={config.colors.main}>
          匯出款項後，請務必以官方LINE訊息、臉書訊息或信箱告知以下資訊，以便會計進行款項確認。謝謝。
        </Text>
      </Row>
      {config.information.offline_bank && (
        <React.Fragment>
          <Divider />
          <Row>
            <Text size="sm" color={textColor} style={{marginRight: 10}}>
              收款銀行：
            </Text>
            <Text size="sm" color={textColor}>
              {config.information.offline_bank}
            </Text>
          </Row>
          <Row>
            <Text size="sm" color={textColor} style={{marginRight: 10}}>
              收款帳號：
            </Text>
            <Text size="sm" color={textColor}>
              {config.information.offline_account}
            </Text>
          </Row>
          <Row>
            <Text size="sm" color={textColor} style={{marginRight: 10}}>
              收款戶名：
            </Text>
            <Text size="sm" color={textColor}>
              {config.information.offline_account_name}
            </Text>
          </Row>
        </React.Fragment>
      )}
    </OrderBlock>
  );
}
