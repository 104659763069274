import React, {useState} from 'react';
import {Input, Button, Heading, Row, Text} from '../Widgets';
import {message, Spin, DatePicker} from 'antd';
import formatValidator from '../Utils/formatValidator';
import {ErrOrder, errorHandler} from '../errors';
import moment from 'moment';
import styled from 'styled-components';
const appConfig = require('../data.json');

const {isNotEmpty} = formatValidator;

export default function PaymentOfflineModal({
  values: _values,
  onClose,
  onEdit,
}) {
  const [values, _setValues] = useState(
    {
      ..._values,
      remitted_time: _values.remitted_time || new Date(),
    } || {
      remittance_account: '',
      remittance_number: '',
      remitted_time: new Date(),
    },
  );
  const [loading, setLoading] = useState(false);

  function setValues(obj) {
    _setValues((prev) => ({...prev, ...obj}));
  }

  const valid = (values) => {
    if (
      !isNotEmpty(values.remittance_number) ||
      !/^\d{5}$/g.test(values.remittance_number)
    ) {
      throw new ErrOrder('請確認帳號為後五碼數字');
    }
  };

  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <Row>
            <Text size="sm" color={appConfig.colors.text}>
              臨櫃匯款資訊
            </Text>
          </Row>
          <Row>
            <Text size="sm" color={appConfig.colors.main}>
              匯款完成後回填以下資訊，供後台人員對帳
            </Text>
          </Row>
          <Row>
            <Text
              size="sm"
              color={appConfig.colors.text}
              style={{marginRight: 10}}>
              *轉帳戶名
            </Text>
            <Input
              value={values.remittance_account}
              onChange={(e) => setValues({remittance_account: e.target.value})}
            />
          </Row>
          <Row>
            <Text
              size="sm"
              color={appConfig.colors.text}
              style={{marginRight: 10}}>
              *轉帳後五碼
            </Text>
            <Input
              value={values.remittance_number}
              onChange={(e) => setValues({remittance_number: e.target.value})}
            />
          </Row>
          <Row>
            <Text
              size="sm"
              color={appConfig.colors.text}
              style={{marginRight: 10}}>
              *轉帳時間
            </Text>
            <DatePicker
              defaultValue={moment(values.remitted_time)}
              onChange={(value, dateString) => {
                setValues({remitted_time: dateString});
              }}
              format="YYYY-MM-DD HH:mm"
              showTime
              style={{
                width: 250,
                height: 40,
              }}
            />
          </Row>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <Button css="margin: 10px;" onClick={() => onClose()}>
            取消
          </Button>
          <Button
            css="margin: 10px;"
            onClick={async () => {
              setLoading(true);
              try {
                valid(values);
                await onEdit(values);
                onClose();
                message.success('填寫成功，等待工作人員對帳。');
              } catch (err) {
                errorHandler(err);
              }
              setLoading(false);
            }}>
            確定
          </Button>
        </div>
      </div>
    </Spin>
  );
}

const metadata = {
  title: (
    <div
      style={{
        padding: '8px 0',
        backgroundColor: '#fff6c6',
        borderRadius: '10px 10px 0 0',
      }}>
      匯款資訊
    </div>
  ),
  width: '600px',
};

export {metadata};
